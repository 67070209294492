<template>
  <div class="page__risk_pop-riskPop">
    <SLazyMount>
      <s-dialog
        :visible="info.show && !info.forcePwd"
        class="page__risk_pop-riskvalidate"
        :append-to-body="true"
        show-close
        @close-from-icon="handleClose"
      >
        <p class="sub-title">
          {{ langText.SHEIN_KEY_PWA_18016 }}
        </p>
        <div class="content">
          <div class="input-area">
            <login-input
              :disabled="true"
              :model-value="info.aliasMark"
              :label="aliasText"
            />
          </div>
          <div class="input-area">
            <login-input
              v-model="code"
              :label="langText.SHEIN_KEY_PWA_17724"
              :tips="codeTip.txt"
              @update:model-value="handleCodeChange"
            >
              <template #end>
                <div>
                  <s-button
                    v-show="codeDownTime <= 0"
                    class="code-btn"
                    :type="['primary', 'H48PX']"
                    da-event-expose="2-8-64"
                    @click="handleSendRiskCode()"
                  >
                    {{ codeIsSended ? langText.SHEIN_KEY_PWA_14897 : langText.SHEIN_KEY_PWA_17205 }}
                  </s-button>
                  <s-button
                    v-show="codeDownTime > 0"
                    class="code-btn"
                    disabled
                    :type="['primary', 'H48PX']"
                  >
                    {{ codeDownTimeFormat }}
                  </s-button>
                </div>
              </template>
            </login-input>
          </div>
          <div class="actions">
            <s-button
              width="100%"
              :type="['primary']"
              @click="handleConfirm"
            >
              {{ langText.SHEIN_KEY_PWA_14896 }}
            </s-button>
          </div>
          <div
            class="warm-tips"
            @click="onLineHelp"
            v-html="warmTips"
          ></div>
        </div>
      </s-dialog>
    </SLazyMount>
    <SLazyMount>
      <s-drawer
        class="page__risk_pop-riskvalidate"
        :visible="info.show && info.forcePwd"
        type="full"
        :title="langText.SHEIN_KEY_PWA_17711"
        :append-to-body="true"
        direction="rtl"
        @close-from-icon="handleClose"
      >
        <s-loading
          type="newpage"
          :show="info.loading"
        />
        <s-alert
          v-show="activeIndex != 2"
          type="error"
        >
          {{ langText.SHEIN_KEY_PWA_17710 }}
        </s-alert>
        <div class="risk-process">
          <div class="process-item ">
            <span
              v-if="activeIndex == 0"
              :class="['ball_num', activeIndex == 0 ? 'active' : '']"
            >1</span>
            <i
              v-else
              class="iconfont icon-normal-checked"
            ></i>
            <span
              v-show="activeIndex == 0"
              class="process-title"
            >{{ langText.SHEIN_KEY_PWA_17713 }}</span>
          </div>
          <span
            :class="['process-line', activeIndex == 0 ? 'short-line' : '']"
          ></span>
          <div class="process-item">
            <span
              v-if="activeIndex != 2"
              :class="['ball_num', activeIndex == 1 ? 'active' : '']"
            >2</span>
            <i
              v-else
              class="iconfont icon-normal-checked"
            ></i>
            <span
              v-show="activeIndex == 1"
              class="process-title"
            >{{ langText.SHEIN_KEY_PWA_15287 }}</span>
          </div>
          <span
            :class="['process-line', activeIndex == 1 ? 'short-line' : '']"
          ></span>
      
          <div class="process-item">
            <span :class="['ball_num', activeIndex == 2 ? 'active' : '']">3</span>
            <span
              v-show="activeIndex == 2"
              class="process-title"
            >{{ langText.SHEIN_KEY_PWA_17714 }}</span>
          </div>
        </div>
        <div
          v-show="activeIndex == 0"
          class="tabs-content"
        >
          <div class="input-area">
            <login-input
              :disabled="true"
              :model-value="info.aliasMark"
              :label="aliasText"
            />
          </div>
          <div class="input-area">
            <login-input
              v-model="code"
              :label="langText.SHEIN_KEY_PWA_17724"
              :tips="codeTip.txt"
              @update:model-value="handleCodeChange"
            >
              <template #end>
                <div>
                  <s-button
                    v-show="codeDownTime <= 0"
                    class="code-btn"
                    :type="['primary', 'H48PX']"
                    da-event-expose="2-8-64"
                    @click="handleSendRiskCode()"
                  >
                    {{ codeIsSended ? langText.SHEIN_KEY_PWA_14897 : langText.SHEIN_KEY_PWA_17205 }}
                  </s-button>
                  <s-button
                    v-show="codeDownTime > 0"
                    class="code-btn"
                    disabled
                    :type="['primary', 'H48PX']"
                  >
                    {{ codeDownTimeFormat }}
                  </s-button>
                </div>
              </template>
            </login-input>
          </div>
          <div class="actions">
            <s-button
              width="100%"
              :type="['primary']"
              @click="handleVerifyCode"
            >
              {{ langText.SHEIN_KEY_PWA_15326 }}
            </s-button>
          </div>
        </div>
        <!-- reset password area -->
        <div
          v-show="activeIndex == 1"
          class="tabs-content password-area"
        >
          <div class="input-filed page__risk-inputFiled">
            <login-input
              v-model="password"
              type="password"
              :clearable="false"
              :label="langText.SHEIN_KEY_PWA_15072"
              :tips="passwordTip.txt"
              @blur="handleBlurPsd"
              @update:model-value="checkPasswordRank"
            />
          </div>
          <div
            v-show="!safety.hideAll"
            class="page__risk_password-regexp"
          >
            <div :class="{ 'normal-red': safety.showAll }">
              <p :class="{ 'tips-greenp': safety.showFir }">
                · {{ langText.SHEIN_KEY_PWA_16140 }}
              </p>
              <p :class="{ 'tips-greenp': safety.showSec }">
                · {{ langText.SHEIN_KEY_PWA_16141 }}
              </p>
              <p :class="{ 'tips-greenp': safety.showThi }">
                · {{ langText.SHEIN_KEY_PWA_16142 }}
              </p>
              <p :class="{ 'tips-greenp': safety.showFour }">
                . {{ langText.SHEIN_KEY_PWA_16892 }}
              </p>
            </div>
          </div>
          <div class="input-filed page__risk-inputFiled">
            <login-input
              v-model="confirmPassword"
              type="password"
              :clearable="false"
              :label="langText.SHEIN_KEY_PWA_15181"
              :tips="confirmPasswordTip.txt"
              @blur="handleConfirmPasswordBlur"
              @update:model-value="handleConfirmPasswordChange"
            />
          </div>
          <div class="actions">
            <s-button
              width="100%"
              :type="['primary']"
              @click="handleUpdateRiskChalleage"
            >
              {{ langText.SHEIN_KEY_PWA_15631 }}
            </s-button>
          </div>
        </div>
        <!-- success -->
        <div
          v-show="activeIndex == 2"
          class="tabs-content success-area"
        >
          <i class="iconfont icon-success"></i>
          <h3 tabindex="0">
            {{ langText.SHEIN_KEY_PWA_17718 }}
          </h3>
          <p tabindex="0">
            {{ langText.SHEIN_KEY_PWA_17753 }}
          </p>
          <div class="actions">
            <s-button
              :type="['primary']"
              width="100%"
              @click="complexSuccessConfirm"
            >
              {{ langText.SHEIN_KEY_PWA_15631 }}
            </s-button>
          </div>
        </div>
        <div
          v-show="activeIndex != 2"
          class="warm-tips"
          @click="onLineHelp"
          v-html="warmTips"
        ></div>
      </s-drawer>
    </SLazyMount>
  </div>
</template>

<script>
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent } from 'vue'
import { riskSendCodeSer, riskCodeVerifySer, updateRiskChalleageSer } from '../service'
import { request } from '../common/tools'
import { passwordPattren } from '../utils'
import LoginInput from '@login/libs/LoginInput/index.vue'
import analysisIndex from '@login/analysis/index'
const { sensorsSend } = analysisIndex

const { IS_RW } = gbCommonInfo
let delayPasswordRankTimer = null

export default defineComponent({
  name: 'CommonRiskValidte',
  components: {
    LoginInput,
    SLazyMount,
    SButton,
    SDialog,
    SLoading,
    SAlert,
    SDrawer,
  },
  props: {
    langText: { type: Object, default: () => ({}) },
  },
  data: () => ({
    code: '',
    codeTip: { show: false, txt: '' },
    codeIsSended: false,
    codeDownTime: 0,
    info: { show: false, alias: '', aliasMark: '', loading: false, forcePwd: false, channel: '' },
    activeIndex: 0,
    password: '',
    passwordTip: { show: false, txt: '' },
    showPassword: false,
    confirmPassword: '',
    showConfirmPassword: false,
    confirmPasswordTip: { show: false, txt: '' },
    safety: {
      showFir: 0,
      showSec: 0,
      showThi: 0,
      showAll: 0,
      hideAll: 1
    },
    step2Loading: false,
  }),
  computed: {
    biScene () {
      return this.info?.bi || this.info?.scene || ''
    },
    warmTips() {
      if (IS_RW) {
        return (this.langText.SHEIN_KEY_PWA_17754 || '').replace('#1F5884', '#FF9999')
      }
      return this.langText.SHEIN_KEY_PWA_17754
    },
    codeDownTimeFormat () {
      const [mins, seconds] = [
        Math.floor(this.codeDownTime / 60),
        this.codeDownTime % 60,
      ]
      return `${mins}:${seconds > 9 ? seconds : '0' + seconds }`
    },
    aliasText () {
      const type = this.info.channel == 'phone_msg' ? 'phone' : 'email'
      if (type == 'email') {
        return this.langText.SHEIN_KEY_PWA_15580
      }
      return this.langText.SHEIN_KEY_PWA_17715
    }
  },
  watch: {
    'info.show' (v) {
      if (v) {
        sensorsSend('2-16-1', { scene: this.info?.from || '' })
      }
    }
  },
  methods: {
    handleSendRiskCode () {
      this.reuqestSendRiskCode()
    },
    async reuqestSendRiskCode () {
      const { unlogin = 1, risk_id, alias, aliasMark, channel, message_type, scene, area_code, encrypt_email = '', encrypt_phone } = this.info

      let target = encrypt_email ? aliasMark : alias

      if (channel == 'phone_msg') {
        target = encrypt_phone ? aliasMark : `${area_code}${alias}`
      }

      const params = { unlogin, risk_id, channel, target, message_type, scene }
            
      if (encrypt_email) params.encrypt_email = encrypt_email
      if (encrypt_phone) params.encrypt_phone = encrypt_phone

      const data = await this.requestSendCodeMethod(params)
      const { code, info, tips } = data
      sensorsSend('2-16-3', { result: code == 0 ? '0' : '1', scene: this.biScene, from: this.codeIsSended ? '0' : '1' })
      if (code == 0 && info.sended == 1) {
        this.startDownTime(120)
        this.codeIsSended = true
        SToast(this.langText.SHEIN_KEY_PWA_17775)
        return
      }

      if (code == 0 && info?.sended == -1) {
        const countdown_second = info.countdown_second
        this.codeIsSended = true
        this.startDownTime(countdown_second)
        // this.codeTip.txt = this.langText.SHEIN_KEY_PWA_17712
        SToast(this.langText.SHEIN_KEY_PWA_17712)
        return
      }

      SToast(tips || this.langText.SHEIN_KEY_PWA_14899)

    },
    async requestSendCodeMethod (params) {
      const { codeUrl } = this.info
      if (codeUrl) { // 单独验证发送接口
        const data = await request('post', codeUrl, params)
        return data
      }
      const data = await riskSendCodeSer(params)
      return data
    },
    startDownTime (time) {
      const n = time <= 0 ? 0 : time
      this.codeDownTime = n
      if (this.downtimer) clearTimeout(this.downtimer)
      if (n <= 0) return
      this.downtimer = setTimeout(() => {
        this.startDownTime(n - 1)
      }, 1000)
    },
    handleCodeChange () {
      this.codeTip.show = false
      this.codeTip.txt = ''
    },
    handleConfirm () {
      if (!this.code) return
      this.info.loading = true
      this.cb({ code: this.code })
    },
    async onLineHelp () {
      const { robotLinkCommon } =  await import('public/src/pages/common/utils/index.js') 
      location.href = robotLinkCommon()
    },
    updateRiskInfo (param = {}, cb) {
      if (typeof cb == 'function') this.cb = cb
      this.info = { ...this.info, ...param }
      if (param.show === true) {
        this.info.forcePwd = !!param.forcePwd
        this.info.loading = false
        this.code = ''
        this.codeDownTime = 0
        this.codeTip.txt = ''
        if (param.forcePwd) this.activeIndex = 0
        this.reuqestSendRiskCode()
      }
    },
    updateErrorTips (msg) {
      this.codeTip.txt = msg
    },
    handleBlurPsd() {
      this.checkPasswordRank()
    },
    // 检测密码等级
    checkPasswordRank() {
      const password = this.password
      //   const rawPsd = password.replace(/\s+/g, '')

      if (password.length >= 8) {
        this.safety.showFir = 1
      } else {
        this.safety.showFir = 0
      }

      if (/[a-zA-Z]+/.test(password)) {
        this.safety.showSec = 1
      } else {
        this.safety.showSec = 0
      }
      if (/[0-9]+/.test(password)) {
        this.safety.showThi = 1
      } else {
        this.safety.showThi = 0
      }
      if (/\s/g.test(password)) {
        this.safety.showFour = 0
      } else {
        this.safety.showFour = 1
      }
      if (!passwordPattren.test(password)) {
        this.safety.showAll = 1
      }

      // 延迟检测隐藏全部选项
      if (delayPasswordRankTimer) clearTimeout(delayPasswordRankTimer)
      delayPasswordRankTimer = setTimeout(() => {
        if (!!this.safety.showFir && !!this.safety.showSec && !!this.safety.showThi && !!this.safety.showFour) {
          this.safety.hideAll = 1
        } else {
          this.safety.hideAll = 0
        }
      }, 200)
    },
    checkPsd(psd) {
      this.checkPasswordRank()
      return passwordPattren.test(psd)
    },
    handleConfirmPasswordBlur () {
      // checkPw(this.confirmPassword, this.confirmPasswordTip)
      if (this.confirmPassword != this.password) {
        this.showInputTips(this.confirmPasswordTip, this.langText.SHEIN_KEY_PWA_15183)
      }
    },
    handleConfirmPasswordChange () {
      this.showInputTips(this.confirmPasswordTip, '')
    },
    showInputTips (tip, msg, show = true) {
      tip.show = show
      tip.txt = msg
    },
    handleClose () {
      this.info.show = false
            
      this.cb({ type: this.activeIndex == 2 ? 'updatePwd' : 'close' })
    },
    async handleVerifyCode () {
      if (!this.code) return
            
      this.codeTip.txt = ''
      this.info.loading = true
      const { risk_id, scene } = this.info

      const data = await riskCodeVerifySer({  risk_id, scene, content: this.code })

      const { code, tips } = data
      this.info.loading = false
      if (code == 0) {
        this.activeIndex = 1
        return
      }
      this.showInputTips(this.codeTip, tips || this.langText.SHEIN_KEY_PWA_14899)
    },
    checkUpdateParams () {
      const { password, confirmPassword } = this
      if (!passwordPattren.test(password)) return false
      if (password != confirmPassword) {
        this.showInputTips(this.confirmPasswordTip, this.langText.SHEIN_KEY_PWA_15183)
        return false
      }
      return true
    },
    async handleUpdateRiskChalleage () {
            
      if (!this.checkUpdateParams()) return

      const { risk_id, scene, channel, aliasMark, area_code = '', encrypt_email, encrypt_phone_alias } = this.info
      const password = this.password
      const content = this.code
      const alias_type = channel == 'phone_msg' ? '2' : '1'
      const params = { risk_id, scene, content, password, alias_type, alias: aliasMark, area_code, encrypt_email, encrypt_phone_alias  }

      this.info.loading = true
      const data = await updateRiskChalleageSer(params)
      this.info.loading = false

      const { code, tips } = data

      if (code == 0) {
        this.activeIndex = 2
        return
      }

      this.showInputTips(this.passwordTip, tips || this.langText.SHEIN_KEY_PWA_14899)

    },
    complexSuccessConfirm () {
      this.info.show = false
      this.cb({ type: 'updatePwd' })
    }
  },
})
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity */
.page__risk_pop-riskvalidate {
    .risk-process {
		line-height: 1.17333rem;
		text-align: center;
		border-bottom: 1px solid #E5E5E5;
        display: flex;
        justify-content: center;
        align-items: center;
        .process-title {
            padding: 0 4px;
        }
		.process-item {
			display: inline-block;
			vertical-align: middle;
		}

		.process-line {
			width: 1.0666rem;
			height: 0.04rem;
			background: #E5E5E5;
			display: inline-block;
			margin: 0 0.10666rem;
			vertical-align: middle;
		}
		.short-line {
			width: 0.2666rem;
		}
		.ball_num, .icon-normal-checked {
			width: 0.43rem;
			height: 0.43rem;
			line-height: 0.42666rem;
			border: 0.04rem solid rgba(0, 0, 0, 0.1);
			display: inline-block;
			background: #ccc;
			color: #fff;
			border-radius: 50%;
			overflow: hidden;
			text-align: center;
			font-size: 12px;
			vertical-align: middle;
            position: relative;
            top: -1px;
		}
		.icon-normal-checked {
			background: #222;
		}
		.active {
			background: #222;
		}
	}
    .sub-title {
        text-align: center;
        color: @sui_color_gray_dark1;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 16px;
    }
    .input-area {
        margin-bottom: 12px;
    }
    .input_filed {
        .txt-l();
    }
    .actions {
        margin-top: 20px;
    }
    .warm-tips {
        margin: 20px 0;
        font-size: 12px;
        font { cursor: pointer; }
        text-align: center;
        padding: 0 24px;
    }
    .tabs-content {
        padding: 8px 24px;  
    }
    .sui-loading {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: @zindex-hack;
        .sui-loading__new-page-inner {
            top: 30%;
        }
    }
    .success-area {
        text-align: center;
        i {
            font-size: 60px;
            color: @sui_color_success;
        }
    }
.page__risk_password-regexp {
  color: #999;
  margin: 10px 0;

  .normal-red {
    color: @sui_color_unusual;

    p {
      line-height: 1.5;

      &.tips-redp {
        color: @sui_color_unusual;
      }

      &.tips-greenp {
        color: @sui_color_safety;
      }
    }
  }

  >p {
    line-height: 1.5;
  }
}
}
</style>
